import { render, staticRenderFns } from "./BackLink.vue?vue&type=template&id=64883ba2&scoped=true&"
import script from "./BackLink.vue?vue&type=script&lang=js&"
export * from "./BackLink.vue?vue&type=script&lang=js&"
import style0 from "./BackLink.vue?vue&type=style&index=0&id=64883ba2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64883ba2",
  null
  
)

export default component.exports